<template>
  <div class="d-flex justify-content-center" id="fq-game-success">
    <img src="/assets/img/fqgame/pass.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'FQGameSuccess'
}
</script>
